.home-container {
  display: flex;
  flex-flow: row;
  margin: 20px;
}

.robot-container {
  width: 300px;
  background-color: rgb(247, 245, 245);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  margin-left: 100px;
  margin-top: 37px;
}

.robot-list {
  width: 300px;
}

.robot-image {
  height:100px;
}

.btn-edit {
  float: right;
}