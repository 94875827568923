.main-panel > .footer {
  height: 50px;
  padding: 0 10px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    margin-right: 30px;
    a {
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
    }
  }
  .copyright {
    color: #9B9B9B;	font-family: "Open Sans";	font-size: 11px;	line-height: 15px;	text-align: right;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-color: white;
  color: $light;
  margin-top: 100px;

  .footer-content {
    width: 100%;

    .logo-container {
      width: 10%;
      min-width: 100px;
      padding: 20px 0;
      display: inline-block;
      vertical-align: top;

      .footer-logo {
        height: 40px;
      }
    }

    .footer-section {
      width: 20%;
      min-width: 200px;
      padding: 20px 0;
      display: inline-block;
      vertical-align: top;

      .section-title {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: .5px;
        color: $brand-gray-1;
      }

      .section-content {
        font-size: 13px;

        a {
          display: block;
        }
      }

      .btn {
        padding: 5px 20px;

        a {
          &:hover {
            text-decoration: none;
          }

          .btn-icon {
            color: $brand-gray-1;
            font-size: 16px;
            vertical-align: middle;
          }

          .btn-text {
            font-size: 13px;
            color: $brand-gray-1;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
