.slide-container {
  display: flex;
  flex-flow: column;
  max-width: 92%;
  min-height: 400px;
  border-radius: 6px;

  margin: auto;
  margin-top: 20px;
  border: 1px solid rgb(112, 112, 112);
}

.slide-forms {
  display: flex;
  flex-flow: row;
  min-height: 400px;

}

.slide-name {
  width: 15%;
  display: flex;
  flex-flow: column;
  padding: 20px;
}

.save-presentation {
  position: absolute;
  top: 35px;
  right: 45px;
  height: 60px;
}

.slide-content {
  padding: 20px;
  width: 70%;
  min-height: 100%;
  display: flex;
  flex-flow: column;
}

.slide-buttons {
  padding: 20px;
  width: 15%;
  display: flex;
  flex-flow: column;
}

.slide-image-content {
  padding: 20px;
  width: 100%;
  min-height: 300px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 6px;
  background-color: white;
}

.slide-textarea {
  width: 50%;
}

.text-form {
  display: flex;
  flex-flow: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-soundpreview {
  float: right;
}

.input-image {
  margin-top: 10px;
  margin-bottom: 0px;
  overflow: hidden;
  position: relative;
  float: right;
  border: 1px solid rgb(150, 150, 150);
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}

.input-image [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.slide-image {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.btn-save-pres {
  position: fixed;
  top: 200px;
  right: 50px;
}

.presentations-list {
  position: relative;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  max-width: 802px;
  min-height: 500px;
  border-radius: 6px;
  background-color: transparent;
  padding: 10px;
  padding-bottom: 60px;
}

.presentation-button {
  border: 1px solid rgb(112, 112, 112) !important;
  border-radius: 6px;
  background-color: white !important;
  color: black !important;
  margin: 5px !important;
  height: 150px;
  width: 250px;
  overflow: hidden;
}

.preview-presentation {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.presentation-item {
  display: flex;
  flex-flow: column;
}

.pres-container {
  margin-left: auto;
  margin-right: auto;
}

.presentation-buttons {
  position: absolute;
  right: 35px;
  bottom: 45px;
  display: flex;
  flex-flow: row;
}

.presentation-buttons * {
  margin: 5px;
}

.name-form {
  margin: auto;
  margin-top: 10px;
  width: 802px;
}

.video-player {
  width: 100%;
}

.big-and-miniature {
  display: flex;
  flex-flow: row;
}

.miniature-slides {
  float: left;
  position: fixed;
  padding: 20px;
  width: 250px;
  height: 600px;
  margin-bottom: 10px;
  background-color: rgb(243, 243, 243);
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
}

.miniature-container {
  padding: 10px;
  width: 200px;
  min-height: 130px;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  margin-bottom: 5px;
  position: relative;
}

.miniature-image {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.miniature-video-player {
  width: 100%;
  object-fit: contain;
  overflow: hidden;
}

.action-buttons {
  display: flex;
  flex-flow: row;
}

.pause-field {
  width: 100px !important;
  margin: 5px;
  height: 38px;
}

.pause-btn {
  width: 80px !important;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 38px;
}
