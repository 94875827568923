.admin-container {
  min-width: 400px; 
  display: flex;
  flex-flow: row;
}

.form-container {
  width: 800px;
  display: flex;
  flex-flow: column;
  margin: 30px;
}

.form-btn {
  background-color: $brand-color !important;
}

.account-list {
  width: 250px;
  margin: 30px;
}
