.robotsContainer{
  padding: 15px;
  .card{
    .contentmanagement{
        .user-wrapper {
          .user {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            .photo {
              height: 61px;
              width: 61px;
              margin: auto;
      
            }
            .photo-container {
              width: 61px;
              height: 61px;
              border-radius: 50%;
              border: 1px solid #FFFFFF;
              overflow: hidden;
            }
            .userinfo {
              margin-left: 10px;
              flex-grow: 1;
              .username {
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
              }
              .title {
                font-size: 12px;
                font-weight: 300;
                line-height: 17px;
              }
            }
            .collapse-arrow {
              font-size: 30px;
              transition: all 0.3s ease;
            }
            .collapse-arrow.active {
              transform: rotate(180deg);
            }
          }
          .user-nav > li > a {
            padding-left: 90px;
            color: white;
            &:hover, &:active {
              color: black;
              background: white;
            }
            &:focus{
              color: white;
              background: transparent;
            }
          }
        }
      }
    }
  }
