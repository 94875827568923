@keyframes notif {
    from {border: 3px solid #99c0ff;}
    to {border: 3px solid #ff7070;}
}

.patientWaiting {
    animation-duration: 1s;
    animation-name: notif;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    text-align: center;
    font-weight: 6px;
    border: 3px solid #99c0ff;
    padding: 5px;
    border-radius: 10px;
    color: #282eac;
    background-color: #c9dfff;
    width: 100px;
    height: 80px;
    position: absolute;
    top: 25px;
    right: 216px;
    cursor: pointer;
}

