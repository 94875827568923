.wallpaper-container {
  display: flex;
  flex-flow: row;
}

.back-container {
  margin: 30px;
  display: flex;
  flex-flow: column;
}

.form-container {
  display: flex;
  flex-flow: column;
  max-width: 300px;
}

.back-image {
  margin-left: 30px;
  margin-top: 30px;
  width: 250px;
  object-fit: contain;
}

.images-container {
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  max-width: 600px;
  height: 400px;
  border-radius: 5px;
  padding: 10px;
}

.image-content {
  width: 150px;
}

.image-card {
  width: 150px;
}

.image-content :hover {
  border: 1px solid rgb(66, 66, 66);
}

.btn-set {
  height: 15px !important;
  float: right !important;
}

.selected-wallpaper {
  padding-top: 5px;
  padding-bottom: 5px;
}

.wallpaper-buttons {
  position: absolute;
  right: 35px;
  bottom: 45px;
  display: flex;
  flex-flow: row;
}

.wallpaper-buttons * {
  margin: 5px;
}

.flex-wallpaper {
  position: relative;
}

.upload-button {
  position: absolute;
  right: 35px;
  bottom: 45px;
}